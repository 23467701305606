$screenxs: 480px
$screensm: 576px
$screenmd: 768px
$screenlg: 992px
$screenxl: 1200px
$screenxxl: 1600px
$marginvalue: 12px
$branddefault: #1c3f95
$brandaccent: #e30f08


//Internal variables

$logowidth: 116px
$navmenucolor: transparent
$navhovercolor: white
$footerheight: $marginvalue * 4

html, body, #root
  height: 100%
  width: 100%
  overflow-x: hidden

.ant-layout
  height: 100%
  width: 100%

.nonheader_wrapper
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
  overflow-y: auto

.ts_content
  padding: ($marginvalue * 2) $marginvalue ($marginvalue * 3) $marginvalue
  flex-shrink: 0
  overflow-x: auto

.ts_full_width
  width: 100%


.header
  height: 128px
  background-color: #ffffff


.header-logo
  display: block
  text-decoration: none
  max-height: 112px
  margin: 4px 0 0 11px
  float: left

.header-company-name
  display: block
  text-decoration: none
  max-height: 60px
  margin-left: 31px
  margin-top: 8px
  line-height: 24px
  font-size: 20px
  float: left
  color: #000000

.header-credit-limit
  display: block
  text-decoration: none
  max-height: 52px
  margin: 8px 0 0 31px
  line-height: 24px
  font-size: 20px
  float: left


.ts-header-container
  max-width: 1920px
  margin: 0 auto
  padding-left: 70px
  padding-right: 70px


.nav
  height: 48px
  background-color: $branddefault
  box-shadow: 0 1px 1px rgba(0,0,0,0.05), 0 2px 2px rgba(0,0,0,0.05), 0 4px 4px rgba(0,0,0,0.05), 0 8px 8px rgba(0,0,0,0.05), 0 16px 16px rgba(0,0,0,0.05), 0 32px 32px rgba(0,0,0,0.05)
  margin-bottom: 24px
  line-height: 46px
  > .ant-menu-light
    background: $branddefault
    border-bottom: none
    > .ant-menu-item
      font-weight: 600
      font-size: 13px
      line-height: 44px
      text-transform: uppercase
      color: #ffffff
      border-top: 2px solid transparent
      height: 48px
      border-bottom: none
    > .ant-menu-item a
      color: #ffffff
    > .ant-menu-item a:hover
      color: $branddefault
    > .ant-menu-item-active
      background: #ffffff
      color: $brandaccent
      border-color: $brandaccent
      border-top: 2px solid
      border-bottom: none
    > .ant-menu-submenu
      font-weight: 600
      font-size: 13px
      line-height: 44px
      text-transform: uppercase
      color: #ffffff
      border-top: 2px solid transparent
      height: 48px
      border-bottom: none
    > .ant-menu-submenu-active
      background: #ffffff
      color: $brandaccent
      border-color: $brandaccent
      border-top: 2px solid
      border-bottom: none



.nav-menu
  text-align: center


.ts-portal-card
  border-top: 2px solid $brandaccent
  background-color: #ffffff
  padding: 16px
  margin: 6px
  box-shadow: 0 1px 1px rgba(0,0,0,0.02), 0 2px 2px rgba(0,0,0,0.03), 0 4px 4px rgba(0,0,0,0.04), 0 8px 8px rgba(0,0,0,0.05)
  min-height: 150px


.ts-address-card
  border-top: 2px solid $brandaccent
  background-color: #ffffff
  padding: 16px
  margin: 16px
  box-shadow: 0 1px 1px rgba(0,0,0,0.02), 0 2px 2px rgba(0,0,0,0.03), 0 4px 4px rgba(0,0,0,0.04), 0 8px 8px rgba(0,0,0,0.05)
  min-height: 150px



.ts-home-content-container
  max-width: 1300px
  margin: 0 auto


.ts-allocations-content-container
  max-width: calc(100% - 64px)
  margin: 0 auto


.ts_allocations
  & .ant-table
    font-size: 12px
  & td
    padding: 4px 4px !important


.ts-rma-content-container
  max-width: 800px
  margin: 0 auto



.ts-footer
  background: #383838
  color: #ffffff


.ts-home-menu
  border-right: none


.login-form
  max-width: 400px

.login-form-forgot
  float: right


.login-alert
  margin-bottom: 16px

.ts-form-margin
  margin-top: 16px


.ts-pdf-modal
  width: 95% !important
  height: 95vh !important
  > .ant-modal-content
    height: 100%
    > .ant-modal-body
      height: calc(100% - 130px)

.ts-pdf
  width: 100%
  height: 100%


.ts-card-dark-border
  border-color: #b4b4b4


.ts-pre-wrap
  white-space: pre-wrap

.ts_scroll_x
  overflow-x: auto

.ts_table_export_button
  margin-left: 32px


a.ts_underline_link
  text-decoration: underline
  &:active
    text-decoration: underline
  &:visited
    text-decoration: underline
  &:hover
    text-decoration: underline
  &:link
    text-decoration: underline